$(document).ready(function () {
    $('#contact_formular').validationEngine({promptPosition: 'bottomLeft'});

    $('#searchprofile_formular').validationEngine({promptPosition: 'bottomLeft'});

    $('.phone_box').focusin(function () {
        $(this).children('p').show();
        if (window.reportHeight) {
            reportHeight();
        }
    });

    const $consentChecks = $('#consentForm input[type=checkbox]');
    $('#consentForm button[type=submit]').prop('disabled', !$consentChecks.toArray().every(input => input.checked));

    $consentChecks.change(function () {
        $('#consentForm button[type=submit]').prop('disabled', !$consentChecks.toArray().every(input => input.checked));
    });

    $('#consentForm').submit(function (e) {
        e.preventDefault();
        $('#consentForm button[type=submit]').prop('disabled', true).html('Bitte warten...');
        $.post(FF.baseUrl + 'index.php/forms/ajaxConsent', $(this).serialize())
            .then(
                () => {
                    $("#consentForm").html("<p class='message-success'>Ihre Einwilligung wurde gespeichert.</p>");
                    setTimeout(() => window.location.reload(), 5000);
                },
                () => {
                    $("#consentForm").html("<p class='message-error'>Ihre Einwilligung konnte nicht gespeichert werden. Bitte versuchen Sie es später noch einmal.</p>");
                });
    });

    const $consentRevokeForm = $('#consentRevokeForm');
    $consentRevokeForm.submit(function (e) {
        e.preventDefault();
        $consentRevokeForm.find('button[type=submit]').prop('disabled', true).html('Bitte warten...');
        $.post(FF.baseUrl + 'index.php/forms/ajaxConsentRevoke', $(this).serialize())
            .then(
                () => $consentRevokeForm.html("<p class='message-success'>Die Löschung Ihrer Daten wurde beantragt.</p>"),
                () => $consentRevokeForm.html("<p class='message-error'>Die Löschung Ihrer Daten konnte leider nicht beantragt werden. Bitte versuchen Sie es später noch einmal.</p>")
            );
    });
});

function onReCaptchaLoad() {
    $('.ff-form-captcha').each(function() {
        const $submitButton = $(this).closest('form').find('[type="submit"]');
        grecaptcha.render(this, {
            sitekey: FF.reCaptchaSiteKey,
            callback: function() { $submitButton.removeAttr('disabled'); },
            'expired-callback': function() { $submitButton.attr('disabled', true); }
        })
    });
}
